$(document).ready(function(){


  $('.tech-carousel').slick({
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '0',
    prevArrow:"<img class='a-left control-c prev slick-prev' src='assets/images/carousel/previous.png'>",
    nextArrow:"<img class='a-right control-c next slick-next' src='assets/images/carousel/next.png'>",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0',
          slidesToShow: 2
        }
      }
    ]
  });
});