$(document).ready(function(){
  var $parentBtn;
  var $label;

  function resetBtns(){
    $parentBtn.css({
      'color': '#b7b7b7',
      'border': '1.5px solid rgba(183, 183, 183, 0.3)'
    });

    $label.css({
      'color': '#b7b7b7'
    });
  }

  $('.radio-btn > input').click(function(){
    console.log(typeof $parentBtn)
    if(typeof $parentBtn != 'undefined') { resetBtns(); }

    $parentBtn = $(this).parent();
    $label = $(this).next();

    $parentBtn.css({
      'color': '#f8ea71',
      'border': '1.5px solid #f8ea71'
    });

    $label.css({
      'color': '#f8ea71'
    });
  });
});